export default [
  {
    category: true,
    title: 'Main Menu',
  },
  // {
  //   title: 'Dashboard',
  //   key: 'Dashboard',
  //   icon: 'fe fe-bookmark',
  //   url: '/admin/dashboard',
  // },
  {
    title: 'Master Data',
    key: 'Master Data',
    icon: 'fe fe-bookmark',
    url: '/admin/master',
  },
  // {
  //   title: 'Class',
  //   key: 'Class',
  //   icon: 'fe fe-bookmark',
  //   url: '/admin/class',
  // },
  // {
  //   title: 'Student',
  //   key: 'Student',
  //   icon: 'fe fe-bookmark',
  //   url: '/admin/student',
  //   status: 'Walikelas',
  // },
  // {
  //   title: 'Extracurricular',
  //   key: 'Extracurricular',
  //   icon: 'fe fe-bookmark',
  //   url: '/admin/extracurricular',
  //   // status: 'Walikelas',
  // },
  {
    title: 'Student Candidate',
    key: 'Student Candidate',
    icon: 'fe fe-user',
    url: '/admin/student-candidate',
  },
  {
    title: 'Blocked Account',
    key: 'Blocked Account',
    icon: 'fe fe-bookmark',
    url: '/admin/blocked-account',
  },
]
