<template>
  <div :class="$style.dropdown">
    <a-drawer
      :closable="true"
      :visible="false"
      placement="left"
      :wrapClassName="$style.mobileMenu"
      @close="toggleMobileMenu"
    >
      <!-- <cui-menu-left /> -->
    </a-drawer>
    <a-badge @click="toggleMobileMenu">
      <a-avatar
        shape="square"
        icon="menu"
        size="large"
        style="backgroundcolor: var(--kit-color-primary)"
      />
    </a-badge>
  </div>

  <!-- <a-dropdown :trigger="['click']" placement="bottomLeft">
    <div :class="$style.dropdown">
      <div :class="$style.account">
        <div :class="$style.name">{{user.nama ? user.nama : user.username}}</div>
        <div :class="$style.desc">{{role}} | {{nisOrNik}}</div>
      </div>
    </div>
    <a-menu slot="overlay">
      <a-menu-item>
        <a @click.prevent="account" href>
          <a-icon class="mr-2" type="user" />My Account
        </a>
      </a-menu-item>
      <a-menu-divider />
      <a-menu-item>
        <a @click.prevent="logout" href style="color: red;">
          <a-icon class="mr-2" type="logout" />Logout
        </a>
      </a-menu-item>
    </a-menu>
  </a-dropdown>-->
</template>

<script>
// import CuiMenuLeft from '@/components/cleanui/layout/Menu/MenuLeft'
export default {
  components: {
    // CuiMenuLeft,
  },
  data() {
    return {
      visibleModalLogout: false,
      status: '',
    }
  },
  methods: {
    account() {
      if (this.stillExam) {
        this.$notification.error({
          message: 'On Exam',
          description: 'Sorry. You are on exam right now.',
        })
      } else if (this.isLive) {
        this.$notification.error({
          message: 'Sorry.',
          description: 'You are live on class meeting now. Please leave meeting first if you want to do this actions.',
        })
      } else {
        if (this.role === 'Teacher') {
          this.$router.push({ name: 'Account Teacher' })
          this.$store.commit('menu/SET_STATE', {
            currentMenu: ['Dashboard Teacher'],
          })
        } else if (this.role === 'Student') {
          this.$router.push({ name: 'Account Student' })
          this.$store.commit('menu/SET_STATE', {
            currentMenu: ['Dashboard Student'],
          })
        } else if (this.role === 'Admin') {
          this.$router.push({ name: 'Account Admin' })
          this.$store.commit('menu/SET_STATE', {
            currentMenu: ['Master Admin'],
          })
        }
      }
    },
    logout() {
      if (this.stillExam) {
        this.$notification.error({
          message: 'On Exam',
          description: 'Sorry. You are on exam right now.',
        })
      } else if (this.isLive) {
        this.$notification.error({
          message: 'Sorry.',
          description: 'You are live on class meeting now. Please leave meeting first if you want to do this actions.',
        })
      } else if (!this.ppdbIsFulfilled) {
        this.$notification.error({
          message: 'Sorry.',
          description: 'Please finish your PPDB first.',
        })
      } else {
        this.$confirm({
          title: 'Logout?',
          content: (
            <div>Once you log out, you need to log in again.</div>
          ),
          onOk: () => {
            this.$store.dispatch('user/LOGOUT')
          },
          centered: true,
          icon: 'warning',
          okType: 'danger',
          okText: 'Logout',
        })
      }
    },
    toggleMobileMenu() {
      const value = !this.settings.isMobileMenuOpen
      this.$store.commit('CHANGE_SETTING', { setting: 'isMobileMenuOpen', value })
    },
  },
  computed: {
    settings() {
      return this.$store.state.settings
    },
    isLive() {
      return this.$store.state.live.isLive
    },
    isOnExam() {
      return this.$store.state.cbt.isOnExam
    },
    isOnExamState() {
      return JSON.parse(localStorage.isOnExam)
    },
    user() {
      return this.$store.state.user.user
    },
    role() {
      const role = this.$store.state.user.role
      if (role.includes('guruMapel')) {
        return 'Teacher'
      } else if (role.includes('murid')) {
        return 'Student'
      } else {
        return 'Other'
      }
      // else if (role.includes('admin') || role.includes('HR') || role.includes('keuangan')) {
      //   return 'Admin'
      // }
      // else {
      //   return 'Other'
      // }
    },
    nisOrNik() {
      if (this.user.role.includes('murid')) {
        return this.user.nis
      } else if (this.user.role.includes('guruMapel')) {
        return this.user.nik
      } else {
        return '-'
      }
    },
    nisOrNikStatus() {
      if (this.user.role.includes('murid')) {
        return 'NIS'
      } else if (this.user.role.includes('guruMapel')) {
        return 'NIK'
      } else {
        return '-'
      }
    },
    stillExam() {
      if (this.isOnExam || (this.isOnExamState && !this.isOnExam)) {
        return true
      } else {
        return false
      }
    },
    ppdbIsFulfilled() {
      return this.$store.state.ppdb.ppdbIsFulfilled
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
